<template>
  <div>
    <div v-if="careGiverList">
      <div>
        <div :class="{ invalid: $v.form.caregivers.$error }">
          <v-autocomplete
            v-model.trim="$v.form.caregivers.$model"
            :items="careGiverList"
            item-text="name"
            item-value="id"
            label="Caregiver "
            persistent-hint
            @change="getClients"
          ></v-autocomplete>

          <div class="invalid-feedback" v-if="$v.form.caregivers.$error">
            <span v-if="$v.form.caregivers.$error">Caregiver is required</span>
          </div>
        </div>
      </div>
      <div>
        <div :class="{ invalid: $v.form.clients.$error }">
          <v-autocomplete
            v-model.trim="$v.form.clients.$model"
            :items="ClientList"
            item-text="clientName"
            item-value="clientId"
            label="Clients"
            persistent-hint
            @change="getService"
          ></v-autocomplete>

          <div class="invalid-feedback" v-if="$v.form.clients.$error">
            <span v-if="$v.form.clients.$error">Client is required</span>
          </div>
        </div>
      </div>

      <div v-if="serviceListData">
        <div :class="{ invalid: $v.form.services.$error }">
          <v-autocomplete
            v-model.trim="$v.form.services.$model"
            :items="serviceListData"
            item-text="subCode"
            item-value="authorizationNumber"
            label="Service/Sub Code "
            persistent-hint
            @change="SetMonths"
          ></v-autocomplete>

          <div class="invalid-feedback" v-if="$v.form.services.$error">
            <span v-if="$v.form.services.$error">Service is required</span>
          </div>
        </div>
      </div>
      <div v-else>
        <v-autocomplete
          v-model="form.services"
          disabled
          :items="serviceListData"
          item-text="name"
          item-value="id"
          label="Service/Sub Code"
          persistent-hint
        ></v-autocomplete>
      </div>
      <div>
        <v-menu
          ref="menu"
          v-model="menu"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Month"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            :min="setAuthDateS"
            :max="setAuthDateE"
            no-title
          >
          </v-date-picker>
        </v-menu>
      </div>

      <div class="float-right">
        <span>
          <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
        </span>
        <span class="m-4">
          <a @click="Apply" class="btn btn-success btn-sm"
            >Submit
            <span v-if="loader">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span>
          </a>
        </span>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import VueTimepicker from "vue2-timepicker";
import { validationMixin } from "vuelidate";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "screen1",
  mixins: [validationMixin],
  components: { VueTimepicker },
  validations: {
    form: {
      clients: { required },
      caregivers: { required },
      services: { required },
    },
  },
  data() {
    return {
      form: {
        clients: null,
        caregivers: null,
        services: null,
      },
      ClientList: [],
      caregiversName: "",
      loader: false,
      clientName: "",
      serviceName: "",
      date: new Date().toISOString().substr(0, 7),
      menu: false,

      serviceListData: [],
    };
  },

  watch: {
    serviceList: {
      handler: function () {},
    },
  },
  created() {},
  computed: {
    ...mapGetters([
      "getclientListTimeEntry",
      "careGiverList",
      "serviceList",
      "getWorkedHour",
      "caregiverList",
    ]),
    setAuthDateS() {
      if (this.getWorkedHour && this.getWorkedHour.allocateHours) { 
        // this.date =
        //   new Date(this.getWorkedHour.allocateHours.startDate).getFullYear() +
        //   "-" +
        //   (parseInt(new Date(this.getWorkedHour.allocateHours.startDate).getMonth()) + 1);
        return this.getWorkedHour.allocateHours.startDate;
      } else return null;
    },
    setAuthDateE() {
      if (this.getWorkedHour && this.getWorkedHour.allocateHours) {
        return this.getWorkedHour.allocateHours.endDate;
      } else return null;
    },
  },
  mounted() {
    // this.$store.dispatch("getClientList");
    this.$store.dispatch("getCareGiverList");
  },
  methods: {
    CheckServiceMonth(item) {
      alert(item);
    },
    getClients() {
      if (this.form.caregivers) {
        this.$store
          .dispatch("getClientsByEmployeeId", { Id: this.form.caregivers })
          .then((res) => {
            this.ClientList = this.getclientListTimeEntry;
          });
      }
    },
    SetMonths() { 
      if (
        new Date(
          this.serviceListData.filter(
            (e) => e.authorizationNumber == this.form.services
          )[0].auth.endDate
        ) < new Date()
      ) {
        this.date = new Date(
          this.serviceListData.filter(
            (e) => e.authorizationNumber == this.form.services
          )[0].auth.endDate
        )
          .toISOString()
          .substring(0, 7);
      }
      this.$store.dispatch("getWorkedHours", {
        employeeId: this.form.caregivers,
        clientId: this.form.clients,
        serviceId: this.serviceListData.filter(
          (e) => e.authorizationNumber == this.form.services
        )[0].subCodesId,
        Mnth: this.date,
        Authnum: this.form.services,
      });
    },
    getCaregivers() {
      if (this.form.clients) {
        this.$store
          .dispatch("getCaregiverById", { clientId: this.form.clients })
          .then((res) => {});
      }
    },
    getService() {
      this.serviceListData = [];
      this.form.services = null;

      if (this.form.caregivers && this.form.clients) {
        this.$store
          .dispatch("getSubCodesById", {
            caregiverId: this.form.caregivers,
            clientId: this.form.clients,
            reqFromWeb:true
          })
          .then((response) => {
            this.isfetching = false;
            this.serviceListData = response[0].casegiverAssociatedSubcodes.map(
              (x) => {
                x.subCode = x.subCode + "-Auth# " + x.authorizationNumber;
                return x;
              }
            );
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }
    },

    async Apply() {
      this.loader = true;
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        await this.$store
          .dispatch("getWorkedHours", {
            employeeId: this.form.caregivers,
            clientId: this.form.clients,
            serviceId: this.serviceListData.filter(
              (e) => e.authorizationNumber == this.form.services
            )[0].subCodesId,
            Mnth: this.date,
            Authnum: this.form.services,
          })
          .then((res) => {
            this.caregiversName = this.careGiverList.filter(
              (e) => e.id == this.form.caregivers
            )[0].name;
            this.clientName = this.getclientListTimeEntry.filter(
              (e) => e.clientId == this.form.clients
            )[0].clientName;
            this.serviceName = this.serviceListData.filter(
              (e) => e.authorizationNumber == this.form.services
            )[0].subCode;
            this.payrate = this.serviceListData.filter(
              (e) => e.authorizationNumber == this.form.services
            )[0].payRate;
            this.loader = false;
            this.$bvModal.hide("filter");

            this.form.date = this.date;
            this.form.authorizationNumber = this.form.services;
            this.form.services = this.serviceListData.filter(
              (e) => e.authorizationNumber == this.form.services
            )[0].subCodesId;
            this.$emit("screenOneData", this.form);
          });
      }
      this.loader = false;
    },

    RESET() {
      (this.clients = []),
        (this.caregivers = []),
        (this.services = []),
        this.$bvModal.hide("filter");
    },
  },
  handleOk(bvModalEvt) {
    // Prevent modal from closing
    bvModalEvt.preventDefault();
    // Trigger submit handler
    this.handleSubmit();
  },
};
</script>

<style scoped>
.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>
