<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <h5>
          Employee : <small class="text-color">{{ setCaregiverName }}</small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          Auth # : <small class="text-color">{{ setAuthNum }}</small>
        </h5>
      </div>
    </div>
    <div class="row p-0 mt-3">
      <div class="col-md-4">
        <h5>
          Client : <small class="text-color">{{ setClintName }} </small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          Service : <small class="text-color">{{ setServiceName }}</small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          <span class="hours-remaining" v-b-tooltip.hover title="Hours remaining for current month">
            Hours Remaining :</span>
          <small v-if="setcurrentHour >= 0" class="text-color">{{
            setcurrentHour
          }}</small>
          <small v-else class="text-color" style="color: red">{{
              setcurrentHour
            }}</small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          Month : <small class="text-color">{{ month }}</small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          Hours Authorized :
          <small class="text-color">{{ setHoursAuthorize }}</small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          Auth Date :
          <small class="text-color">
            {{ foramtDateHeading(setAuthDateS) }} -
            {{ foramtDateHeading(setAuthDateE) }}</small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          Pay Rate : <small class="text-color"> $ {{ setPayRate }}</small>
        </h5>
      </div>
      <div class="col-md-4">
        <h5>
          Usage Note : <small class="text-color">{{ setUsageNotes }} </small>
        </h5>
      </div>
    </div>
    <hr />
    <div class="row p-0 mt-3">
      <div class="col-md-3" v-if="ClientsList">
        <v-autocomplete v-model="form.clients" :items="ClientsList" item-text="clientName" item-value="clientId"
          label="Clients" persistent-hint @change="getClientName"></v-autocomplete>
      </div>
      <div class="col-md-3" v-if="serviceListData">
        <v-autocomplete v-model="form.services" :items="serviceList[0].casegiverAssociatedSubcodes" item-text="subCode"
          item-value="authorizationNumber" label="Service/Sub Code " persistent-hint
          @change="getServiceName"></v-autocomplete>
      </div>

      <div class="col-md-3">
        <v-menu ref="menu" v-model="menu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date" label="Month" prepend-icon="mdi-calendar" readonly v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="date" type="month" :min="setAuthDateS" :max="setAuthDateE" no-title
            @change="getServiceName">
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div style="color: red">{{ message }}</div>
    <div class="row">
      <div class="col-md-8">
        <table class="hoursTable">
          <thead>
            <tr>
              <td>Date</td>
              <td>Hours</td>
              <td>Start Time</td>
              <td>End Time</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ dobformat(item.Date) }}</td>
              <td>
                <input type="number" placeholder="Hour" v-model="item.Hours" @input="hoursUpdate(item, i)" />
              </td>
              <td>
                <vue-timepicker manual-input id="time" @change="handleTimeStartChange(i)" v-model="item.dateStart"
                  :format="format" :minute-interval="15"
                ></vue-timepicker>
              </td>
              <td> <vue-timepicker manual-input id="time" @change="handleTimeEndChange(i)" v-model="item.dateEnd"
                  :format="format" :minute-interval="15"
                ></vue-timepicker></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="col-md-4">
        <table class="hoursTable">
          <thead>
            <tr>
              <td>Date</td>
              <td>Hours</td>
              <td>Start Time</td>
              <td>End Time</td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="i > 14 && i < 30" v-for="(item, i) in items" :key="i">
              <td>{{ dobformat(item.Date) }}</td>
              <td>
                <input
                  type="number"
                  placeholder="Hour"
                  v-model="item.Hours"
                  @input="hoursUpdate(item)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <div class="col-md-4">
        <table class="hoursTable">
          <thead>
            <tr>
              <td>Date</td>
              <td>Hours</td>
              <td>Start Time</td>
              <td>End Time</td>
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-if="i > 29" v-for="(item, i) in items" :key="i">
              <td>{{ dobformat(item.Date) }}</td>
              <td>
                <input
                  type="number"
                  placeholder="Hour"
                  v-model="item.Hours"
                  @input="hoursUpdate(item)"
                />
              </td>
            </tr> -->
            <tr>
              <td>
                <a style="font-weight: 600; font-size: x-large">Total = {{ totalHours }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="float-right">
      <span class="m-4">
        <a @click="Submit" class="btn btn-success btn-sm">Submit
          <span v-if="loader">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </span>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
export default {
  name: "screen2",
  components: { VueTimepicker, },
  props: ["form"],
  data() {
    return {
      selectedTime: '', // Data property to hold the selected time
      format: 'HH:mm', // Format of the time (24-hour format)

      loader: false,
      timeEntries: [],
      authName: "",
      totalHours: 0,
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      serviceListData: [],
      items: [],
      fields: [
        {
          key: "Date",
          label: "Date",
        },

        {
          key: "Hours",
          label: "Hours",
          thStyle: { width: "30%" },
        },
        {
          key: "dateStart",
          label: "dateStart",
          thStyle: { width: "30%" },
        },
        {
          key: "dateEnd",
          label: "dateEnd",
          thStyle: { width: "30%" },
        },
      ],
      message: null,
      month: "",
    };
  },

  watch: {
    setRemainHour: {
      handler: function () {
        this.showwarning();
      },
    },
    setcurrentHour: {
      handler: function () {
        this.showwarningmsj();
      },
    },
  },
  created() { },
  computed: {
    ...mapGetters([
      "ClientsList",
      "getclientListTimeEntry",
      "careGiverList",
      "serviceList",
      "getWorkedHour",
      "GetAuthenticatedUser",
    ]),
    setCaregiverName() {
      return (this.caregiversName = this.careGiverList.filter(
        (e) => e.id == this.form.caregivers
      )[0].name);
    },
    setClintName() {
      return (this.clientName = this.getclientListTimeEntry.filter(
        (e) => e.clientId == this.form.clients
      )[0].clientName);
    },
    setAuthNum() {
      if (this.getWorkedHour && this.getWorkedHour.allocateHours) {
        this.authName = this.getWorkedHour.allocateHours.authorizationNumber;
        return this.getWorkedHour.allocateHours.authorizationNumber;
      } else return null;
    },
    setServiceName() {
      return (this.serviceName =
        this.serviceList[0].casegiverAssociatedSubcodes.filter(
          (e) => e.authorizationNumber == this.form.services
        )[0] != undefined
          ? this.serviceList[0].casegiverAssociatedSubcodes.filter(
            (e) => e.authorizationNumber == this.form.services
          )[0].subCode
          : null);
    },
    setPayRate() {
      return (this.payrate =
        this.serviceList[0].casegiverAssociatedSubcodes.filter(
          (e) => e.authorizationNumber == this.form.services
        )[0] != undefined
          ? this.serviceList[0].casegiverAssociatedSubcodes.filter(
            (e) => e.authorizationNumber == this.form.services
          )[0].payRate
          : null);
    },
    setUsageNotes() {
      if (this.getWorkedHour && this.getWorkedHour.allocateHours) {
        return this.getWorkedHour.allocateHours.usageNotes;
      } else return null;
    },
    setRemainHour() {
      if (this.getWorkedHour) {
        return this.getWorkedHour.hoursRemainingisPL1002 == 0
          ? "N/A"
          : this.getWorkedHour.hoursRemaining - this.totalHours;
      } else return null;
    },
    setcurrentHour() {
      if (this.getWorkedHour) {
        return this.getWorkedHour.currentmonh - this.totalHours;
      } else return null;
    },
    setHoursAuthorize() {
      if (this.getWorkedHour && this.getWorkedHour.allocateHours) {
        return this.getWorkedHour.allocateHours.hours;
      } else return null;
    },
    setAuthDateS() {
      if (this.getWorkedHour && this.getWorkedHour.allocateHours) {
        // this.date =  new Date(this.getWorkedHour.allocateHours.startDate).getFullYear() + "-" +
        //   (parseInt(new Date(this.getWorkedHour.allocateHours.startDate).getMonth()) + 1);
        return this.getWorkedHour.allocateHours.startDate;
      } else return null;
    },
    setAuthDateE() {
      if (this.getWorkedHour && this.getWorkedHour.allocateHours) {
        return this.getWorkedHour.allocateHours.endDate;
      } else return null;
    },
  },
  mounted() {
    this.$store.dispatch("getCareGiverList");
    this.form.services = this.form.authorizationNumber;
    this.date = this.form.date;

    this.changeDate();
  },
  methods: {
    handleTimeStartChange(i) {

      this.calculateHours(i)
    },
    handleTimeEndChange(i) {

      this.calculateHours(i)
    },
    calculateHours(index) {
  const item = this.items[index];
  if (item.dateStart && item.dateEnd) {
    const start = new Date(item.Date.setHours(item.dateStart.HH, item.dateStart.mm));
    const end = new Date(item.Date.setHours(item.dateEnd.HH, item.dateEnd.mm));
    const timeDiffInMilliseconds = end - start;
    
    // Convert milliseconds to hours
    const hoursDiff = timeDiffInMilliseconds / (1000 * 60 * 60);

    this.$set(this.items, index, { ...item, Hours: hoursDiff }); // Updating hours field
    setTimeout(() => {
      this.hoursUpdate(index, 0)
    }, 1000);
  }
},
hoursUpdate(data, index) {
  if (typeof data === 'object') {
    const item = this.items[index];
    let numStr = data.Hours.toString();

    // Check if the number is in the range 1-9
    if (data.Hours >= 1 && data.Hours <= 9) {
      // Prepend '0' to the number
      numStr = '0' + numStr;
    }
    this.$set(this.items, index, { ...item, dateStart: { HH: "00", mm: "00" }, dateEnd: { HH: numStr, mm: "00" } }); // Updating hours field       
  }
  this.totalHours = 0;
  this.items.forEach((e) => {
    if (e.Hours) {
      if (e.Hours < 0 || e.Hours > 24) {
        Swal.fire({
          title: "",
          text: "Hours should be less than 24!",
          icon: "warning",
          confirmButtonClass: "btn btn-warning",
        });
        // e.dateStart = { HH: "00", mm: "00" }
        // e.dateEnd = { HH: "00", mm: "00" }
        e.Hours = 0;
      } else {
        // Splitting hours and minutes
        const hours = Math.floor(e.Hours);
        const minutes = (e.Hours - hours) * 60;
        // Adding hours and converted minutes to total hours
        this.totalHours += hours + (minutes / 60);
      }
    }
  });
  this.hoursRemaining = this.hoursRemaining - this.totalHours;
},
    clear(data) {
      data.Hours = "";
    },
    showwarning() {
      if (this.setRemainHour < 0) {
        Swal.fire({
          title: "",
          text: "Remaining  Hours are less than zero !",
          icon: "warning",
          confirmButtonClass: "btn btn-warning",
        });
      }
    },
    showwarningmsj() {
      if (this.setcurrentHour < 0) {
        Swal.fire({
          title: "",
          text: "Remaining  Hours are less than zero !",
          icon: "warning",
          confirmButtonClass: "btn btn-warning",
        });
      }
    },
    async getService() {
      this.serviceListData = [];
      if (this.form.caregivers && this.form.clients) {
        await this.$store
          .dispatch("getSubCodesById", {
            caregiverId: this.form.caregivers,
            clientId: this.form.clients,
            reqFromWeb: true
          })
          .then((response) => {
            this.isfetching = false;
            this.serviceListData = response[0].casegiverAssociatedSubcodes;
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }
    },

    specficHoursDate(data) {
      var dateString = new Date().toUTCString();
      var hoursString = data;
      var parts = hoursString.replace(/AM|PM/, "").split(":");
      var hours =
        parseInt(parts[0]) + (hoursString.indexOf("PM") !== -1 ? 12 : 0);
      var minutes = parts[1];
      var date = new Date(dateString);
      date.setUTCHours(hours, minutes);
      return new Date(date.toUTCString());
    },
    dobformat(obj) {
      if (obj) {
        var week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let day = week[new Date(obj).getDay()];
        let d = new Date(obj);
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        return `${day}, ${mo} ${da}, ${ye}`;
      }
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          +(date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          date.getFullYear()
        );
      }
    },
    getClientName() {
      this.totalHours = 0;
      this.clientName = this.getclientListTimeEntry.filter(
        (e) => e.clientId == this.form.clients
      )[0].clientName;

      if (this.form.caregivers && this.form.clients) {
        this.$store
          .dispatch("getSubCodesById", {
            caregiverId: this.form.caregivers,
            clientId: this.form.clients,
            reqFromWeb: true
          })
          .then((response) => {
            this.isfetching = false;
            this.serviceListData = response[0].casegiverAssociatedSubcodes;
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }

      this.getServiceName();
      this.items = null;
      setTimeout(() => {
        this.changeDate();
      }, 500);
    },

    async getServiceName() {
      this.message = null;
      this.items = null;
      this.totalHours = 0;
      setTimeout(() => {
        this.changeDate();
      }, 500);

      await this.$store
        .dispatch("getWorkedHours", {
          employeeId: this.form.caregivers,
          clientId: this.form.clients,
          serviceId: this.serviceList[0].casegiverAssociatedSubcodes.filter(
            (e) => e.authorizationNumber == this.form.services
          )[0].subCodesId,
          Mnth: this.date,
          Authnum: this.form.services,
        })
        .then(() => {
          // this.date =  new Date(this.setAuthDateS).getFullYear() + "-" + (new Date(this.setAuthDateS).getMonth() + 1);
          this.changeDate();
        });

      this.serviceName =
        this.serviceListData.filter(
          (e) => e.subCodesId == this.form.services
        )[0] != undefined
          ? this.serviceListData.filter(
            (e) => e.subCodesId == this.form.services
          )[0].subCode
          : null;
      this.payrate =
        this.serviceListData.filter(
          (e) => e.subCodesId == this.form.services
        )[0] != undefined
          ? this.serviceListData.filter(
            (e) => e.subCodesId == this.form.services
          )[0].payRate
          : null;
    },

    async Submit() {
      var InvalidEntriesCount = 0;
      this.loader = true;
      var vm = this;
      let copyOfItem = JSON.parse(JSON.stringify(this.items))
     copyOfItem.forEach((element) => {
    if (element.Hours < 0 || element.Hours >= 24) {
        InvalidEntriesCount++;
    }
debugger
    if (element.Hours) {
        var dateStart = new Date(element.Date); 
        dateStart.toLocaleString('en-US', {timeZone: 'America/Los_Angeles'});
        console.log(dateStart)
        let hoursDiff = dateStart.getHours() - dateStart.getTimezoneOffset() / 60;
        let minutesDiff = (dateStart.getHours() - dateStart.getTimezoneOffset()) % 60;
        dateStart.setHours(hoursDiff);
        dateStart.setMinutes(minutesDiff);
        console.log(Number(element.dateStart.HH))
        console.log(Number(element.dateEnd.HH))
        let shour = 0;
        let ehour =0;
        if (dateStart.getTimezoneOffset() === 480)  {
 
          shour = Number(element.dateStart.HH)  - 8;
ehour = Number(element.dateEnd.HH) - 8;
        }else{
          shour = Number(element.dateStart.HH)  - 7;
ehour = Number(element.dateEnd.HH) - 7;
        }
      //  shour = Number(element.dateStart.HH)  - 7;
      //  ehour = Number(element.dateEnd.HH) - 7;
        const sMM = Number(element.dateStart.mm);
        const eMM = Number(element.dateEnd.mm);
        
        let startTime = new Date(element.Date);
        startTime.setHours(shour);
        startTime.setMinutes(sMM);

        let endTime = new Date(element.Date);
        endTime.setHours(ehour);
        endTime.setMinutes(eMM);

        // Calculate the difference in milliseconds
        let timeDiff = endTime - startTime;

        // Convert milliseconds to hours
        let actualHours = timeDiff / (1000 * 60 * 60);

        this.timeEntries.push({
            startTimeDate: startTime,
            endTimeDate: endTime,
            actualHours: parseFloat(actualHours.toFixed(2)), // Round to 2 decimal places
        });
    }
});

      if (InvalidEntriesCount == 0) {
        if (this.timeEntries.length > 0) {
          await this.$store
            .dispatch("PostManualEntry", {
              clientId: this.form.clients,
              caregiverId: this.form.caregivers,
              timeEntries: this.timeEntries,
              subCodeId: this.serviceList[0].casegiverAssociatedSubcodes.filter(
                (e) => e.authorizationNumber == this.form.services
              )[0].subCodesId,
              authorizationNumber: this.authName,
              createdBy: this.GetAuthenticatedUser.userName,
            })
            .then((response) => {
              if (response.message == "Success") {
                if (response.data.length > 0) {
                  Swal.fire({
                    title: "",
                    text:
                      "These Time Entries Already Exist! : " +
                      response.data.toString(),
                    icon: "error",
                    confirmButtonClass: "btn btn-danger",
                  });
                } else {
                  Swal.fire({
                    title: "",
                    text: "Time Entries have been added successfully",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                  });
                  vm.message = "Time Entries have been added successfully";

                  vm.timeEntries = [];

                  vm.loader = false;
                }
                vm.timeEntries = [];
              }
              vm.loader = false;
            });
        } else {
          this.loader = false;
          Swal.fire({
            title: "",
            text: "Enter atleast one entry!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      } else {
        this.loader = false;
        Swal.fire({
          title: "",
          text: "Not All Entries Are Valid",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    changeDate() {
      var scAuthDate = this.setAuthDateS;
      // this.date =  new Date(this.setAuthDateS).getFullYear() + "-" + (new Date(this.setAuthDateS).getMonth() + 1);

      var currenDate = new Date().toISOString();
      var date = new Date(this.date).toISOString();
      var sDate = new Date(scAuthDate);

      if (
        parseInt(date.split("T")[0].split("-")[1]) >
        parseInt(this.setAuthDateE.split("T")[0].split("-")[1]) &&
        parseInt(date.split("T")[0].split("-")[0]) >=
        parseInt(this.setAuthDateE.split("T")[0].split("-")[0])
      ) {
        var D0 = date.split("T")[0];
        var D1 = date.split("T")[1];
        var DD = D0.split("-");
        DD[1] = this.setAuthDateE.split("T")[0].split("-")[1];
        DD = DD.join("-");
        var arr = [];
        arr.push(DD);
        arr.push("T");
        arr.push(D1);
        date = arr.join("");
        this.date = arr.join("").substring(0, 7);
      }
      var fullYear = new Date().getFullYear();
      date = date.substring(0, 10).split("-");
      date = date[1] + "-" + date[2] + "-" + date[0];
      var dateovj = new Date(date);
      this.month = this.monthNames[dateovj.getMonth()];

      this.items = [];
      var lastDate;
      var firstDate;
      var isDatevalid = true;
      if (
        dateovj.getFullYear() == new Date(currenDate).getFullYear() &&
        dateovj.getMonth() > new Date(currenDate).getMonth()
      ) {
        Swal.fire({
          title: "",
          text: "Selected Date Is greater than current date !",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });

        // this.date =
        //   new Date(currenDate).getFullYear() +
        //   "-" +
        //     (new Date(currenDate).getMonth() +
        //     1);
      } else if (
        sDate.getFullYear() == new Date(currenDate).getFullYear() &&
        sDate.getMonth() == new Date(currenDate).getMonth()
      ) {
        lastDate = new Date(currenDate);

        for (var dateI = sDate; dateI < lastDate;) {

          var newDate = new Date(dateI.setDate(dateI.getDate()));
          this.items.push({
            Date: newDate,
            Hours: "",
          });
          dateI.setDate(dateI.getDate() + 1);
        }
      } else if (
        dateovj.getFullYear() == new Date(currenDate).getFullYear() &&
        dateovj.getMonth() == new Date(currenDate).getMonth()
      ) {
        lastDate = new Date(currenDate);
        for (var date = dateovj; date < lastDate;) {
          var newDate = new Date(date.setDate(date.getDate()));

          this.items.push({
            Date: newDate,
            Hours: "",
          });
          new Date(date.setDate(date.getDate() + 1));
        }
      } else if (
        fullYear > dateovj.getFullYear() &&
        this.month != this.monthNames[sDate.getMonth()]
      ) {
        lastDate = new Date(dateovj.getFullYear(), dateovj.getMonth() + 1, 0);
        for (var date = dateovj; date < lastDate;) {
          var newDate = new Date(date.setDate(date.getDate()));
          this.items.push({
            Date: newDate,
            Hours: "",
          });
          new Date(date.setDate(date.getDate() + 1));
        }
        this.items.push({
          Date: lastDate,
          Hours: "",
        });
      } else {
        firstDate = new Date(dateovj.getFullYear(), dateovj.getMonth() + 1, 0);
        if (firstDate.getMonth() + 1 == sDate.getMonth() + 1) {
          for (var date = dateovj; date < firstDate;) {
            var newDate = new Date(date.setDate(date.getDate()));
            if (this.items.length < 30) {
              this.items.push({
                Date: newDate,
                Hours: "",
              });
            }

            new Date(date.setDate(date.getDate() + 1));
          }
          this.items.push({
            Date: firstDate,
            Hours: "",
          });
        } else {
          for (var date = dateovj; date < firstDate;) {
            var newDate = new Date(date.setDate(date.getDate()));

            this.items.push({
              Date: newDate,
              Hours: "",
            });
            new Date(date.setDate(date.getDate() + 1));
          }
          this.items.push({
            Date: firstDate,
            Hours: "",
          });
        }
      }
    },
    foramtDateHeading(obj) {
      if (obj) {
        let d = new Date(obj);
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        return `${mo}/${da}/${ye}`;
      }
    },
    async Apply() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.getworkedHours();
        this.caregiversName = this.careGiverList.filter(
          (e) => e.id == this.form.caregivers
        )[0].name;

        this.clientName = this.getclientListTimeEntry.filter(
          (e) => e.clientId == this.form.clients
        )[0].clientName;
        this.serviceName = this.serviceListData.filter(
          (e) => e.subCodesId == this.form.services
        )[0].subCode;
        this.payrate = this.serviceListData.filter(
          (e) => e.subCodesId == this.form.services
        )[0].payRate;
        this.changeDate();
        this.$bvModal.hide("filter");
        this.$bvModal.show("Action");
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
  color: #2d79bd !important;
}

.v-btn--outlined {
  border: thin solid #2d79bd !important;
}

.text-color {
  color: #2d79bd;
  font-size: 90% !important;
}

.vue__time-picker * {
  text-transform: uppercase;
}

.hours-remaining:hover {
  cursor: pointer;
}

.hoursTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.hoursTable td,
.hoursTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.hoursTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.hoursTable tr:hover {
  background-color: #ddd;
}

.hoursTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.table {
  border: 1px solid black;
  margin: 2rem auto;
  border-radius: 0.3rem;
}

.table>thead>tr>th {
  border: 1px solid black;
  padding: 0.5rem 0.8rem;
}

.table>tbody>tr>td {
  border: 1px solid black;
  padding: 0.5rem 0.8rem;
}

.table>tbody>tr:nth-child(2n + 1) {
  background-color: rgb(239 239 239);
}
</style>

<style scoped></style>
